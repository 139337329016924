import React from "react"

// Components
import Hero from "../../../components/hero"

const JoinOurListHero = () => {
  return (
    <Hero pageTitle="Join our List">
      <p>
        Subscribe to the Visby Medical mailing list.
      </p>
    </Hero>
  )
}
export default JoinOurListHero